<template>
  <div>
    <el-button :size="size" @click="listShow=true">{{isAll?'全部客户':$store.state.currCustomer.dwmch||'请选择客户'}}<i class="el-icon-arrow-down el-icon--right"></i></el-button>
    <el-dialog custom-class="custom-dialog" :visible.sync="listShow" :append-to-body="true" width="1100px" top="15vh">
      <div slot="title">
        <div class="flex-row align-items-center margin-right-20">
          <div class="margin-right-10">搜索客户</div>
          <div>
            <el-input placeholder="请输入关键字搜索" v-model="keyword" style="width: 300px;">
              <el-button slot="append" icon="el-icon-search" class="link" :loading="loading" @click="initList"></el-button>
            </el-input>
          </div>
          <div class="flex-row align-items-center justify-between flex-auto">
            <div class="margin-left-20">当前选择客户：{{isAll?'全部客户':$store.state.currCustomer.dwmch}}</div>
            <el-button v-if="allowSelectAll" size="mini" @click="$emit('selectAll');listShow=false">全部客户</el-button>
          </div>
          
        </div>
      </div>
      <div v-loading="loading">
        <el-table
          ref="singleTable"
          :data="list"
          :height="$root.docHeight*0.8-280"
          stripe
          size="medium"
          :show-header="false"
          style="width: 100%"
          highlight-current-row
        >
          <el-table-column label="客户名称">
            <template slot-scope="props">
              <div style="cursor: default;">{{props.row.dwmch}}</div>
            </template>
          </el-table-column>
          <el-table-column label="客户地址">
            <template slot-scope="props">
              <div style="cursor: default;">{{props.row.dzhdh}}</div>
            </template>
          </el-table-column>
          <el-table-column label="资质状态" width="150">
            <template slot-scope="props">
              <div style="cursor: default;" :class="props.row.cq_count?'color-orange':'color-green'">{{props.row.cq_count?`资质信息超期 ${props.row.cq_count} 项`:'资质正常'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="资质超期数量" width="150">
            <template slot-scope="props">
              <div style="cursor: default;">资质超期数量：{{props.row.cq_count}}</div>
            </template>
          </el-table-column>
          <el-table-column label="选择" width="80">
            <template slot-scope="props">
              <el-button size="mini" :disabled="props.row.cq_count?true:false" @click="setCurrCustomer(props.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex-row justify-center margin-top-20">
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :total="total"
              :page-size="pageSize"
              :current-page.sync="page"
              @current-change='pageChange'
            >
            </el-pagination>
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'CustomerList',
  components: {},
  props:{
    listener:{
      type:Boolean,
      default:true,
    },
    isCart:{
      type:Number,
      default:0,
    },
    isAll:{
      type:Boolean,
      default:false,
    },
    allowSelectAll:{
      type:Boolean,
      default:false,
    },
    size:{
      type:String,
      default:'medium',
    },
  },
  data() {
    return {
      loading:false,
      listShow:false,
      keyword:'',
      list:[],
      total:0,
      page:1,
      pageSize:10
    }
  },
  methods:{
    initList(){
      this.page=1
      this.total=0
      this.getList()
    },
    getList(){
      this.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.page,
        page_size:this.pageSize,
        keyword:this.keyword,
        is_cart:this.isCart
      }
      this.$instance.get('/ywy/get_customer_list',{params})
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
            if(!this.$store.state.currCustomer.user_id){
              this.$store.commit('currCustomer',res.data.data.list.find(v=>{return !v.cq_count})||{})
              try{
              localStorage.setItem('currCustomer',JSON.stringify(this.$store.state.currCustomer))
              }catch{
                //
          }
            }
            this.list=res.data.data.list
            this.total=res.data.data.total
        }else{
          this.list=[]
        }
      })
      .catch(()=> {
        this.list=[]
      }); 
    },
    //切页
    pageChange(res){
      this.page=res
      this.getList()
    },
    setCurrCustomer(val){
      if(val){
        if(val.cq_count){
          this.$message({message: '用户资质信息超期，暂无法选择此用户',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          this.$refs.singleTable.setCurrentRow();
        }else{
          try{
          localStorage.setItem('currCustomer',JSON.stringify(val))
          }catch{
            //
          }
          this.$store.commit('currCustomer',val)
          this.listShow=false
          this.$emit('select',val)
        }
        
      }
      
    },
    getHisCurrCustomer(){
      if (!document.hidden) {
        try{
          this.$store.commit('currCustomer',JSON.parse(localStorage.getItem('currCustomer')||'{}'))

        }catch{
          //
        }
       
      } 
    }
  },
  created(){
    try{
      !this.$store.state.currCustomer.user_id&&this.$store.commit('currCustomer',JSON.parse(localStorage.getItem('currCustomer')||'{}'))
    }catch{
      //
    }
    this.getList()
  },
  mounted(){
    this.listener&&document.addEventListener("visibilitychange", this.getHisCurrCustomer);
  },
  destroyed(){
    this.listener&&document.removeEventListener("visibilitychange", this.getHisCurrCustomer);
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
 
</style>
