<template>
  <div>
    <div class="home safe-area">
      <div class="home-main-top flex-row margin-bottom-40">
        <Classification :defaultShowNum="9"></Classification>
        <el-carousel class="banner" height="360px" :interval="5000">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <div class="link" @click="gotoBannerDetail(item)">
              <el-image class="banner flex-column" :src="item.img_url" fit="cover">
                <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                  <img src="@/assets/images/no-pic.png" height="200px"/>
                </div>
              </el-image>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="flex-auto flex-column justify-between padding-tb-30">
          <div  v-if="$store.state.loginRes.success">
            <div class="flex-row justify-center margin-bottom-10">
              <el-image style="width:50px;height:50px;border-radius:100%;" icon="el-icon-user-solid" :src="$store.state.loginRes.user_profile||avatar" fit="cover"></el-image>
            </div>
            <div class="text-align-center font-size-20 text-overflow-ellipsis" style="width:200px;margin:0 auto;">{{$store.state.loginRes.dwmch}}</div>
          </div>
          <div v-else>
            <div class="text-align-center font-size-20 color-sub">Hi,请登录</div>
            <div class="flex-row align-items-center justify-center font-size-16 margin-top-20">
              <el-button class="custom-btn" size="medium" type="success" round @click="$root.jump({path:'/login'})">登录</el-button>
              <el-button class="custom-btn" size="medium" type="primary" round @click="$root.jump({path:'/register'})">注册</el-button>
            </div>
          </div>
          <div class="flex-row align-items-center justify-between margin-lr-20">
            <div class="text-align-center link" @click="$root.jump({path:'/user/order',query:{type:1}})">
              <el-badge :max="99" :value="orderNum.dtq||''" :hidden="!orderNum.dtq">
                <img src="@/assets/images/order-status-1.png" height="24"/>
              </el-badge>
              <div>待提取</div>
            </div>
            <div class="text-align-center link" @click="$root.jump({path:'/user/order',query:{type:2}})">
              <el-badge :max="99" :value="orderNum.fjz||''" :hidden="!orderNum.fjz">
                <img src="@/assets/images/order-status-2.png" height="24"/>
              </el-badge>
              <div>分拣中</div>
            </div>
            <div class="text-align-center link" @click="$root.jump({path:'/user/order',query:{type:3}})">
              <el-badge :max="99" :value="orderNum.yck||''" :hidden="!orderNum.yck">
                <img src="@/assets/images/order-status-3.png" height="24"/>
              </el-badge>
              <div>已出库</div>
            </div>
            <div class="text-align-center link" @click="$root.jump({path:'/user/order',query:{type:4}})">
              <img src="@/assets/images/order-status-4.png" height="24"/>
              <div>已送达</div>
            </div>
          </div>
          <div class="flex-row align-items-center justify-between margin-lr-20">
            <div class="text-align-center link" @click="$root.jump({path:'/user/collect'})">
              <div class="font-size-20"><i class="el-icon-star-off"></i></div>
              <div>我的收藏</div>
            </div>
            <div class="text-align-center link" @click="$root.jump({path:'/user/buyHistory'})">
              <div class="font-size-20"><i class="el-icon-timer"></i></div>
              <div>快速复购</div>
            </div>
            <div class="text-align-center link" @click="$root.jump({path:'/user/browsingHistory'})">
              <div class="font-size-20"><i class="el-icon-view"></i></div>
              <div>浏览记录</div>
            </div>
          </div>
          
          <div class="border-top padding-top-30 margin-lr-10">
            <el-carousel height="30px" :interval="5000" indicator-position="none" arrow="never" direction="vertical">
              <el-carousel-item v-for="(item,index) in noticeList" :key="index">
                <div class="text-overflow-ellipsis link" @click="$root.jump({path:'/mall/articleDetail',query:{id:item.notice_id}})"><span class="custom-tag margin-right-5" color="yellow">公告</span>{{item.notice_title}}</div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <el-row :gutter="20" type="flex">
        <el-col :span="huodongList.length%4>=huodongList.length-index?24/(huodongList.length%4):6" v-for="(item,index) in huodongList" :key="index">
          <div @click="$root.jump({path:'/mall/prodList',query:{q:crypto.encrypt({hd_id:item.hd_id,hd_name:item.name})}})"
          :style="`background:${item.bcolor||'#2bc2a1'};border-radius:10px;position:relative;overflow:hidden;padding:20px;flex:auto`" class="hover-opacity flex-row align-items-center justify-between margin-bottom-20">
            <div style="position: absolute; background:rgba(255,255,255,0.2);width:250px;height:150px;border-radius:100%; left:-50px;top:-50px;"></div>
            <div style="position: absolute; background:rgba(255,255,255,0.2);width:100%;height:150px;border-radius:100%; left:-50%;bottom:-80px;"></div>
            <div style="position: absolute; background:rgba(255,255,255,0.2);width:350px;height:350px;border-radius:100%; right:-150px;bottom:-100px;"></div>
            <div class="flex-row align-items-center" style="position:relative;">
              <img :src="item.xpic" height="60px"/>
              <div class="margin-left-20">
                <div class="font-size-24 bold">{{item.name}}</div>
                <div class="margin-top-10">{{item.fubiaoti}}</div>
              </div>
            </div>
            <div class="text-align-center" style="position:relative;">
              <i class="el-icon-shopping-cart-2" style="font-size:40px;"></i>
              <div class="text-nowrap">{{item.anniu}}</div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- <div class="flex-row">
        <div v-for="(item,index) in huodongList" :key="index" @click="$root.jump({path:'/mall/prodList',query:{q:crypto.encrypt({hd_id:item.hd_id,hd_name:item.name})}})"
         :style="`background:${item.bcolor||'#2bc2a1'};border-radius:10px;position:relative;overflow:hidden;padding:20px;flex:1`" :class="`hover-opacity flex-row align-items-center justify-between ${index>0?'margin-left-20':''}`">
          <div style="position: absolute; background:rgba(255,255,255,0.2);width:250px;height:150px;border-radius:100%; left:-50px;top:-50px;"></div>
          <div style="position: absolute; background:rgba(255,255,255,0.2);width:100%;height:150px;border-radius:100%; left:-50%;bottom:-80px;"></div>
          <div style="position: absolute; background:rgba(255,255,255,0.2);width:350px;height:350px;border-radius:100%; right:-150px;bottom:-100px;"></div>
          <div class="flex-row align-items-center" style="position:relative;">
            <img :src="item.xpic" height="60px"/>
            <div class="margin-left-20">
              <div class="font-size-24 bold">{{item.name}}</div>
              <div class="margin-top-10">{{item.fubiaoti}}</div>
            </div>
          </div>
          <div class="text-align-center" style="position:relative;">
            <i class="el-icon-shopping-cart-2" style="font-size:40px;"></i>
            <div class="text-nowrap">{{item.anniu}}</div>
          </div>
        </div>
        
      </div> -->
     

      <div class="flex-row align-items-center margin-tb-20">
        <el-image style="width: 24px; height: 24px" :src="columnIcon" fit="contain"></el-image>
        <span class="font-size-20 bold margin-left-10">为您推荐</span>
      </div>
      <div ref="prodList">
        <el-row :gutter="20" type="flex">
          <el-col :span="6" v-for="(item,index) in prods.list" :key="index">
            <div class="product-list-item" @click="$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:item.spid,hd_id:item.hd_id})},blank:$store.state.loginRes.success&&$store.state.loginRes.audit_state==2?false:false})">
              <div class="pic">
                <el-image class="img flex-column" :src="item.img_url" fit="contain">
                  <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                    <img src="@/assets/images/no-pic.png" height="200px"/>
                  </div>
                </el-image>
                <div class="gwc"><el-button @click.stop="addShoppingCart(item)" type="primary" plain :disabled="$store.state.loginRes.audit_state!=2" circle icon="el-icon-shopping-cart-full"></el-button></div>
              </div>
              <div class="padding-15 flex-auto flex-column">
                <div class="flex-auto">
                  <div class="font-size-16 bold">{{item.spmch}}</div>
                  <div class="color-sub margin-top-5">{{item.shengccj}}</div>
                  <div class="color-sub margin-top-5">{{item.shpgg}}</div>
                </div>
                <div class="flex-row align-items-center justify-between margin-top-5">
                  <div class="color-orange">￥<span class="font-size-20 bold ">{{$numFormat(item.hshsj,2)  || item.hshsj}}</span></div>
                  <div class="color-sub font-size-16">库存：{{item.pcjcshl_str}}</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div v-if="prods.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
        <div v-if="prods.noMore" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
      </div>
    </div>
    <el-dialog custom-class="custom-dialog" title="加入购物车"  :visible.sync="addShoppingCartShow" :append-to-body="true" width="1100px" top="15vh">
      <div>
        <AddShoppingCart :q="gwcQuery"></AddShoppingCart>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import Classification from '@/components/Classification.vue'
import AddShoppingCart from '@/components/AddShoppingCart.vue'
import avatar from "@/assets/images/default-photo.png";
import columnIcon from "@/assets/images/scdd-icon.png";
import crypto from "@/common/crypto";
export default {
  components: { Classification,AddShoppingCart },
  name: 'Home',
  data() {
    return {
      crypto,
      avatar:avatar,
      columnIcon:columnIcon,
      bannerList:[],//banner广告图列表
      noticeList:[],//公告列表
      huodongList:[],//活动列表
      prods:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[]
      },
      activeProd:{},
      addShoppingCartShow:false,
      orderNum:{},//订单角标
      bodyScrollHeight:0
    }
  },
  computed:{
    gwcQuery(){
      let q={spid:this.activeProd.spid,hd_id:this.activeProd.hd_id,pihao:this.activeProd.pihao}
      return q
    }
  },
  methods:{
    
    getData(){
      this.getBanner()
      this.getNotice()
      this.getHuodong()
      this.getProdList()
      if(this.$store.state.loginRes.success){
        this.getOrderNum()
      }
    },
    //获取banner
    getBanner(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/main/get_adv_list',{params})
      .then(res=>{
        if(res.data.code==0){
          this.bannerList=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    gotoBannerDetail(obj){
      switch(obj.adv_type){
        case 1:
          window.open(obj.link_url)
        break;
        case 2:
          this.$root.jump({path:'/mall/articleDetail',query:{id:obj.notice_id},blank:false})
        break;
        case 3:
          this.$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:obj.spid,hd_id:obj.hd_id,pihao:obj.pihao})},blank:false})
        break;
      }
    },
    //获取公告列表
    getNotice(){
      let params= {
        ...this.$store.state.basicParams,
        page:1,
        page_size:5
      }
      this.$instance.get('/main/get_notice_list',{params})
      .then(res=>{
        if(res.data.code==0){
          this.noticeList=res.data.data
        }
      })
      .catch(()=> {}); 
    },
     //获取活动
    getHuodong(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/main/get_huodong_list2',{params})
      .then(res=>{
        if(res.data.code==0){
          this.huodongList=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    
    //获取推荐商品列表
    getProdList(){
      this.prods.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.prods.page,
        page_size:this.prods.pageSize
      }
      this.$instance.get('/main/get_tj_product_list',{params})
      .then(res=>{
        this.prods.loading=false
        if(res.data.code==0){
          this.prods.list=this.prods.list.concat(res.data.data.list)
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          if(res.data.data.list.length<this.prods.pageSize){
            this.prods.noMore=true
          }else{this.prods.page+=1}
          if(this.prods.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.prods.loading=false
      }); 
    },
    //加载更多商品
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.prodList,
        allow:!this.prods.loading&&!this.prods.noMore,
        offset:0,
        callBack:this.getProdList,
      })
    },
    //加入购物车
    addShoppingCart(item){
      this.activeProd=item
      this.addShoppingCartShow=true
    },
      //获取订单角标
    getOrderNum(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/order/get_order_ck',{params})
      .then(res=>{
        if(res.data.code==0){
          this.orderNum=res.data.data
        }
      })
      .catch(()=> {
      }); 
    },
  },
  created(){
    this.getData()
  },
  mounted(){

  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .home-main-top{
    height: 360px;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    border-radius: 0 0 10px 10px/0 0 10px 10px;
    background: linear-gradient(to top,#ffe5d8 0px,#fff 36px,#fff);
    overflow: hidden;
   
    .banner{
      width: 760px;
      height: 360px;
      border-radius: 0 0 20px 20px/0 0 20px 20px;
    }
  }
</style>
<style>
  @import '../../assets/css/product-list-item.scss';
</style>
